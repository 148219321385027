<template>
  <el-dialog
    append-to-body
    width="900px"
    title=""
    top="5vh"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="tableWrap">
      <div style="text-align: center;font-size: 16px">
        <span>用工单位：{{dataInfo.detail.company_name}}</span>
      </div>
      <el-descriptions class="margin-top" :title="`工资月份：${dataInfo.detail.date}`" :column="2" size="medium" border >
        <el-descriptions-item :labelStyle='labelStyle' :label="item" v-for="(item,index) in dataInfo.titles.slice(3)" :key="index">
          <span>{{curDetail.slice(2)[index]}}</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
   </el-dialog>
</template>

<script>
export default {
  name: 'Detail',
  data() {
    return {
      visible: false,
      isChange: false,
      dataInfo: {
        titles: [],
        detail: {}
      },
      curDetail: [],
      labelStyle: { 'width': '160px' },
    }
  },
  methods: {
    // 获取组织详情
    getDetail(row) {
      this.isChange = true
      this.$http.get('/admin/finance/salary', {params:{id: row.id}}).then(res => {
        if(res.code == 1) {
          this.dataInfo.titles = res.data.titles;
          this.dataInfo.detail = res.data.detail;
          console.log(this.dataInfo.detail)
          console.log(Object.values(res.data.detail))
          this.curDetail = Object.values(res.data.detail)
        
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      this.isChange = false
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
  }
}
</script>