<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_name">
          <el-input clearable v-model.trim="table.params.company_name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="show_name">
          <el-select v-model="table.params.show_name" clearable filterable placeholder="请输入员工姓名" value-key="id" :filter-method="dataFilter" @change="handleEmployeeChange" >
            <el-option
              v-for="item in employeeOpt"
              :key="item.id"
              :label="item.show_name"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份证号" prop="id_card_no">
          <el-input clearable v-model.trim="table.params.id_card_no" maxlength="18" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="工资月份" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="month"
            placeholder="选择月"
            format="yyyy 年 MM 月"
            value-format="yyyy-MM">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="warning" plain icon="el-icon-download" @click="handleExportBatch">导出</el-button>
      </div>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:action="{row}">
        <el-button type="text" @click='showDetail(row)'>查看</el-button>
      </template>
    </VTable>

    <Detail ref="detail"></Detail>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from './components/Detail.vue'
import {exportData} from "@/util/index"
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    VTable,
    Detail
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"100"},
        { name: "date", label: "工资月份", hidden: false },
        { name: "company_name", label: "单位名称", hidden: false },
        { name: "employee_name", label: "姓名", hidden: false},
        { name: "phone", label: "电话号码", hidden: false},
        { name: "base_salary", label: "基本工资", hidden: false},
        { name: "salary", label: "应发工资", hidden: false},
        { name: "company_manager_fee", label: "管理费", hidden: false},
        { name: "pay_status", label: "开票金额", hidden: false},
        { name: "real_salary", label: "实发工资", hidden: false},
        { name: "action", label: "操作",width: "120", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          show_name: '',
          employee_id: '',
          id_card_no: '',
          date: '',
          company_name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      employeeOpt: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.post('/admin/finance/salaryList', this.table.params).then(res => {
        if(res.code == 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            console.log('删除接口');
            done()
          } else {
            done()
          }
        }
      })
    },
    // 重置
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.employee_id = '';
      this.table.params.page = 1;
      this.getTable();
    },
    // 检索员工
    dataFilter(val) {
      this.$http.get('/admin/employee/searchList',{params:{name:val}}).then(res => {
        if(res.code === 1) {
          this.employeeOpt = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleEmployeeChange(value) {
      this.table.params.employee_id = value.id;
      this.table.params.show_name = value.show_name;
      this.getTable()
    },

    // 批量导出
    handleExportBatch() {
      if(this.table.total > 0) {
        if(!!this.table.params.company_name || !!this.table.params.employee_id || !!this.table.params.id_card_no || !!this.table.params.date) {
          this.$confirm('确认导出工资条吗','提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            exportData(this.table.params,'/admin/finance/exportSalaryList')
          }).catch(() => {})
        } else {
          this.$message.warning("请先检索您要的数据再操作导出")
        }
      } else {
        this.$message.warning("抱歉 暂无数据导出，请先查询")
      }
    },
    
  }
}
</script>
<style scoped>
.width100 {
  width: 180px !important;
}
</style>